function PostHeightIframe(libraryId) {
  setTimeout(() => {
    var elemHeight = document.getElementById("root").clientHeight;
    var event = {
      type: "LOREE_WINDOW_SIZE",
      height: (libraryId === 4 || libraryId === 9) ? elemHeight + 75 : libraryId === 5 ? elemHeight + 40 : elemHeight + 30,
      url: window.location.href
    };
    var canvasWindow = window.parent;
    let url = document.referrer;
 
    if (url !== "" && document.referrer.indexOf(url) >= 0) {
      canvasWindow.postMessage(event, url);
    }
  }, 5000);
}

export default PostHeightIframe;

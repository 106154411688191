import axios from "axios";

axios.defaults.baseURL = "https://loree-int-api.crystaldelta.net";

// Org and User auth check
// Request generator for create content
export let userVerification = async (user, org_id) => {
  let userVerify = {
    method: "POST",
    endPoint: "/user/verify",
    body: {user, org_id}
  };
  let verifiedUser =  await callApi(userVerify);
  sessionStorage.setItem("org_id", org_id);
  sessionStorage.setItem("user_id", verifiedUser.user);
  return verifiedUser;
};

// To fetch the trackingId of each organization
export let fetchGaID = async (orgId) => {
  let data = {
    method: "GET",
    endPoint: "/gaId",
    body: orgId
  };
  let trackingId = await callApi(data);
  sessionStorage.setItem("trakingId", trackingId.gaID);
  return trackingId;
};
// Fetching the library list
export let getLibrary = async () => {
  let data = {
    method: "GET",
    endPoint: "/library"
  };
  let libraryResult = await callApi(data);
  return libraryResult;
};

// Request generator to fetch content
export const fetchContent = async (id, user)=>{
  let apiData = {
    method: "GET",
    endPoint:`/content/${id}`, 
    headers: { user_id: user }
  };
  return await callApi(apiData);
};

// Fetching the pagination content list
export let getPaginationContents = async (user_id,page,limit) => {
  let data = {
    method: "GET",
    endPoint: `/content/paginate/${page}/${limit}`,
    headers: { 
      user_id: user_id, 
      org_id: sessionStorage.getItem("org_id"),
    },
  };
  let contentResult = await callApi(data);
  return contentResult;
};

//view exact content 
export const viewContent = async (id) => {
  let apiData = {
    method: "GET",
    endPoint:`/content/view/${id}`
  };
  return await callApi(apiData);
};


// Fetching the contents list
export let getContents = async (user_id) => {
  let data = {
    method: "GET",
    endPoint: "/content",
    headers: { user_id: user_id, org_id: sessionStorage.getItem("org_id") }
  };
  let contentResult = await callApi(data);
  return contentResult;
};

// Get Individual content
export let getContent = async (id, user) => {
  let data = {
    method: "GET",
    endPoint: `/content/${id}`,
    headers: { user_id: user }
  };
  return await callApi(data);
};

// Request generator for create content
export let createContent = async (data) => {
  // data.user_id = 1;
  let apiData = {
    method: "POST",
    endPoint: "/content",
    body: data
  };
  return await callApi(apiData);
};

// Delete individual content
export let deleteContent = async (id, user_id) => {
  let apiData = {
    method: "DELETE",
    endPoint:`/content/${id}`,
    headers:{ user_id }
  };
  return await callApi(apiData);
};

// Edit individual content
export let editContent = (data) => {
  let apiData = {
    method: "PUT",
    endPoint: `/content/${data.id}`,
    body: data.body
  };
  return callApi(apiData);
};

//To Edit individual status of the created component
export let toggleActive = (id, user_id, lib_id, status) => {
  let apiData = {
    method: "PUT",
    endPoint: `/content/update/${id}`,
    body: {id, user_id, lib_id, active: status }
  };
  return callApi(apiData);
};

// To upload the images form editor to s3
export let uploadToS3 = (file) => {
  let apiData = {
    method: "POST",
    endPoint: "/s3",
    headers: {orgId: sessionStorage.getItem("org_id"), userId: sessionStorage.getItem("user_id")},
    body : file
  };
  return callApi(apiData);
};

export let deleteImagesFromS3 = async (inputData) => {
  let apiData = {
    method: "POST",
    endPoint:"/s3/delete",
    headers: {orgId: sessionStorage.getItem("org_id"), userId: sessionStorage.getItem("user_id")},
    body : inputData
  };
  return await callApi(apiData);
};


/* Will be deleted once all the images of the content are migrated to s3 */
export const getComponentById = async (id) => {
  let apiData = 
  {
    method: "GET",
    endPoint: `/content/component/${id}`,
  };

  return await callApi(apiData);
};

/* Will be deleted once all the images of the content are migrated to s3 */
export const migrateImagesTos3 = async (inputDetails) => {
  let apiData = 
  {
    method: "POST",
    endPoint: "/s3/migrate",
    body: inputDetails
  };

  return await callApi(apiData);
};

/* Will be deleted once all the images of the content are migrated to s3 */
export let saveUpdatedContent = (data) => {
  console.log(data, "updated Content");  /*will remove this console once all the contents are migrated*/
  let apiData = {
    method: "PUT",
    endPoint: `/content/migrate/${data.id}`,
    body: data.parameters
  };
  return callApi(apiData);
};

// Send request to API
let callApi =  async (data) => {
  let response = await axios({
    method: data.method,
    url: data.endPoint,
    headers: data.headers,
    data: data.body
  });
  return response.data;
};

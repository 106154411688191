export const validateForm = (parameters, title, fontSize, ErrorsFetch, contentArr, pathName, type, dndLayout, dndParameters, dndArray) => {
  let values, fail = {};
  if ((dndLayout === "CustomOverlay" && type === "DragandDrop") || type === "Hotspot"){
    dndParameters.forEach((parameters_el, parameters_index) => {
      dndArray.forEach((contentArr_el, contentArr_index) => {
        if (parameters_index === contentArr_index) {
          values = Object.values(parameters_el);
          if (values[0] === "" || values[0] === "<p><br></p>") fail[contentArr_el[0]] = "*Background Image is required";
          if(values[1].length === 0) fail[ contentArr_el[1]] = "* Atleast one dropzone needs to be added";
        }
      });
    });
  } else{
    parameters.forEach((parameters_el, parameters_index) => {
      contentArr.forEach((contentArr_el, contentArr_index) => {
        if (parameters_index === contentArr_index) {
          values = Object.values(parameters_el);
          if (values[0] === "") fail[contentArr_el[0]] = `* ${(type !== "DragandDrop") ? "Section Header" : "Option"} is required`;
          if (values[1] === "") fail[contentArr_el[1]] = `* ${(type !== "DragandDrop") ? "Section Body" : "Backround Image"} is required`;
        }
      });
    });
  }
  if (title === "") {
    fail["title"] =  pathName.includes("/duplicate") ? "* DUPLICATED INTERACTIVE - Please enter a new title":  "* Interactive Title is required";
  }
  if (fontSize < 10) {
    fail["fontSize"] = "* Value must be 10-40";
  } 
  ErrorsFetch({
    errors: fail
  });
  return fail;
};

export const validateDropZone = (dndParameters, index, label, text, activeZone, editStatus) => {
  const textArr=[], labelArr=[], fail={};
  let parameters = Array.from(dndParameters);
  let dropZoneData = Array.from(parameters[index].dropZone);
  if(editStatus){
    const elementsIndex = dropZoneData.findIndex(element => element.id === activeZone);
    if (elementsIndex !== -1) {
      dropZoneData.splice(elementsIndex, 1);
      parameters[index].dropZone = dropZoneData;
    }
  }
  parameters[index].dropZone.map((dropArea)=>{
    textArr.push(dropArea.data.text);
    labelArr.push(dropArea.data.label);
    return null;
  });
  let labelField = document.getElementById("label");
  labelField.innerText = label === "" ? "*Label is required" : labelArr.includes(label) ? "*Label is already exist" : "";
  let textField = document.getElementById("text");
  textField.innerText = text === "" ? "* Text is required" : textArr.includes(text) ? "* Text is already exist" : "";
  if((label === "" || text === "") || (textArr.includes(text) || labelArr.includes(label))){
    fail["error"] = "error";
  }
  return fail;
}; 

export const validateHotspot = (dndParameters, index, label, text, activeZone, editStatus) => {
  const textArr=[], labelArr=[], fail={};
  let parameters = Array.from(dndParameters);
  let dropZoneData =Array.from(parameters[index].dropZone);
  if(editStatus){
    const elementsIndex = dropZoneData.findIndex(element=> element.id === activeZone);
    if(elementsIndex !== -1){
      dropZoneData.splice(elementsIndex, 1);
      parameters[index].dropZone = dropZoneData;
    }
  }
  parameters[index].dropZone.map((dropArea)=>{
    textArr.push(dropArea.data.text);
    labelArr.push(dropArea.data.label);
    return null;
  });
  let refinedText = removeEmptyTags(text);
  let labelField = document.getElementById("label");
  labelField.innerText = label === "" ? "*Label is required" : labelArr.includes(label) ? "*Label is already exist" : "";
  let textField = document.getElementById("text");
  textField.innerText = refinedText === "" ? "* Text is required" : "";
  if((label === "" || refinedText === "")  || labelArr.includes(label)){
    fail["error"] = "error";
  }
  return fail;
};

const  removeEmptyTags = (text) => {
  const wrapper = document.createElement("div");
  wrapper.innerHTML = text;
  let bool = false;
  Array.from( wrapper.childNodes).forEach((child) => {
    if(child.outerHTML !== "<p><br></p>") {
      bool = true;
      return;
    }
    else if (child.outerHTML === "<p><br></p>" && !bool)  {
      child.remove();
    }
  });
  text = wrapper.innerHTML;
  return text;
};

/* eslint-disable react/no-string-refs */
/* eslint-disable no-undefined */
/* eslint-disable no-useless-concat */
import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import { putItems } from "../../redux/components/reducer.action";
import { fetchContent, viewContent } from "../../middleware/api";
import { initializeReactGA } from "../../middleware/googleAnalytics";
import { connect } from "react-redux";
import Header from "../layouts/navbar";
import PostHeightIframe from "../../middleware/sendHeight";

let content, response;
class ViewButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
  }

  //mouse cursor in button
  hoverOn = () => {
    this.setState({ hover: true });
  };

  //mouse cursor leave from button
  hoverOff = () => {
    this.setState({ hover: false });
  };

  //To fetch the library content in DB using fetchContent api
  async componentDidMount() {
    const { PutItems } = this.props;
    const { id } = this.props.match.params;
    if (this.props.location.pathname === `Button/${id}` || `button/${id}`) {
      response = await viewContent(id);
    } else {
      if (
        this.props.match.params.user === undefined ||
        this.props.match.params.user === ""
      ) {
        content = sessionStorage.getItem("user_id");
      } else {
        content = this.props.match.params.user;
      }
      response = await fetchContent(id, content);
    }
    if (!response.message && response.library_id === 3) {

      //To check the tracking id of GA
      if(this.props.location.pathname !== `/button/${id}` ) initializeReactGA(this.props.location.pathname);

      PutItems({
        title: response.title,
        parameters: response.parameters,
        customisations: response.customisations,
      });
    } else {
      this.props.history.push("/*");
    }
    PostHeightIframe();
  }
  render() {
    const { id } = this.props.match.params;
    const { title, parameters, customisations } = this.props;
    const viewpage = this.props.location.pathname;
    const isNewTab = parameters.isChecked ? "_blank" : "_top";
    const mouseover =
      customisations.btnType === "btn btn-outline-dark" ? true : false;
    let btnview;

    const buttonstyle = {
      background: `${customisations.bgColor}`,
      fontFamily: `${customisations.fontFamily}`,
      fontSize: `${customisations.fontSize}px`,
      color: `${customisations.fontColor}`,
      border: "2px solid" + `${customisations.bgColor}`,
      paddingTop: (customisations.customPaddingtop === "") ? 10 : `${customisations.customPaddingtop}px`,
      paddingLeft: (customisations.customPaddingleft === "") ? 10 :`${customisations.customPaddingleft}px`,
      paddingBottom: (customisations.customPaddingbottom === "") ? 10 :`${customisations.customPaddingbottom}px`,
      paddingRight: (customisations.customPaddingright === "") ? 10: `${customisations.customPaddingright}px`
    };

    const buttonUnhover = {
      background: "white",
      color: `${customisations.bgColor}`,
      fontFamily: `${customisations.fontFamily}`,
      fontSize: `${customisations.fontSize}px`,
      border: "2px solid" + `${customisations.bgColor}`,
      paddingTop: (customisations.customPaddingtop === "") ? 10 : `${customisations.customPaddingtop}px`,
      paddingLeft: (customisations.customPaddingleft === "") ? 10 :`${customisations.customPaddingleft}px`,
      paddingBottom: (customisations.customPaddingbottom === "") ? 10 :`${customisations.customPaddingbottom}px`,
      paddingRight: (customisations.customPaddingright === "") ? 10: `${customisations.customPaddingright}px`
    };

    const buttonHover = {
      background: `${customisations.bgColor}`,
      fontSize: `${customisations.fontSize}px`,
      border: "2px solid" + `${customisations.bgColor}`,
      fontFamily: `${customisations.fontFamily}`,
      color: `${customisations.fontColor}`,
      paddingTop: (customisations.customPaddingtop === "") ? 10 : `${customisations.customPaddingtop}px`,
      paddingLeft: (customisations.customPaddingleft === "") ? 10 :`${customisations.customPaddingleft}px`,
      paddingBottom: (customisations.customPaddingbottom === "") ? 10 :`${customisations.customPaddingbottom}px`,
      paddingRight: (customisations.customPaddingright === "") ? 10: `${customisations.customPaddingright}px`
    };
    if (this.props.match.params.user === undefined) {
      btnview = "/editButton";
    } else {
      btnview = `/button/edit/${this.props.match.params.user}`;
    }
    if (viewpage === `/Button/${id}`) {
      return (
        <React.Fragment>
          <div className={"text-" + `${customisations.alignment}`}>
            <a href={parameters.link} type="button" target={isNewTab}>
              {mouseover ? (
                <Button
                  className={`${customisations.btnType}`}
                  style={this.state.hover ? buttonHover : buttonUnhover}
                  onMouseEnter={this.hoverOn}
                  onMouseLeave={this.hoverOff}
                >
                  {parameters.name}
                </Button>
              ) : (
                <Button
                  className={`${customisations.btnType}`}
                  style={buttonstyle}
                >
                  {parameters.name}
                </Button>
              )}
            </a>
          </div>
        </React.Fragment>
      );
    } else if (
      viewpage === `/create/Button/${id}` ||
      `/contentview/button/${this.props.match.params.user}/${id}`
    ) {
      if (this.props.location.state !== undefined) {
        return (
          <React.Fragment>
            <Header
              user={localStorage.getItem("user")}
              user_id={sessionStorage.getItem("user_id")}
              data={this.props}
            />
            <Container className="margin">
              <Row className="d-flex justify-content-between mt-3">
                <Col style={{wordBreak:"break-word"}}>
                  <h2>{title}</h2>
                </Col>
                <Col xs lg="1">
                  <Link
                    style={{ color: "black" }}
                    className="float-right"
                    to={{
                      pathname: `${btnview}/${id}/${sessionStorage.getItem("org_id")}/${sessionStorage.getItem("loreeVersion")}`,
                      state: {
                        user:
                          this.props.location.state !== undefined
                            ? this.props.location.state.user
                            : "",
                        user_id:
                          this.props.location.state !== undefined
                            ? this.props.location.state.user_id
                            : "",
                      },
                    }}
                  >
                    Edit
                  </Link>
                </Col>
              </Row>
              <div></div>
              <div className={"text-" + `${customisations.alignment}`}>
                <div className="m-2 mt-5">
                  <a href={parameters.link} type="button" target={isNewTab}>
                    {mouseover ? (
                      <Button
                        className={`${customisations.btnType}`}
                        style={this.state.hover ? buttonHover : buttonUnhover}
                        onMouseEnter={this.hoverOn}
                        onMouseLeave={this.hoverOff}
                      >
                        {parameters.name}
                      </Button>
                    ) : (
                      <Button
                        className={`${customisations.btnType}`}
                        style={buttonstyle}
                      >
                        {parameters.name}
                      </Button>
                    )}
                  </a>
                </div>
              </div>
            </Container>
          </React.Fragment>
        );
      } else {
        return <Redirect to="/401" />;
      }
    }
  }
}

//state from redux
const mapStateToProps = ({ component }) => ({
  title: component.title,
  parameters: component.parameters,
  customisations: component.customisations,
});

//props to redux
const mapDispatchToProps = dispatch => ({
  PutItems: component => dispatch(putItems(component)),
});

//connecting form with redux
export default connect(mapStateToProps, mapDispatchToProps)(ViewButton);

/* eslint-disable no-undefined */
import React from "react";
import { Redirect } from "react-router-dom";
import { Form, FormControl, Button, Card, Row, Col, Container } from "react-bootstrap";
import { sortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { connect } from "react-redux";
import { addPanel, addOption, handleChange, handleTitle, removeClick, removeOption, rearangeArray, setCheck, errorsFetch, makeEdit, setType, setValue, setShowTip } from "../../redux/multipleChoice/mcq.action";
import { createContent, getContent, editContent } from "../../middleware/api";
import { initializeReactGA } from "../../middleware/googleAnalytics";
import McqPreview from "../Interactives/mcqPreview";
import Editor from "../editor/editor";
import swal from "sweetalert2";
import arrayMove from "array-move";
import Header from "../layouts/navbar";
import "../form/form.scss";
import { validateForm } from "./validate";
import Customize from "./customize";
import {shuffleOptions} from "../Interactives/shuffle";

const inputType = {
  question: "question"
};
const option = { option: "option", mytip: "mytip", isChecked: "correct", isShowtip: "check" };

//Sorting the Items while drag and drop
const SortableItem = SortableElement(({ content }) => {
  return (<div>{content}</div>);
});

//Handle functionality while dragging in the panels
const DragHandle = SortableHandle((props) =>
  <div className="d-block dragDropIcon">
    <div className="d-inline font-weight-bold"><i className="fa fa-arrows-alt"></i> Section {props.value}</div>
  </div>
);

//Allocate the space while drag and drop
const SortableContainer = sortableContainer(({ children }) => {
  return (<Card className="mt-3">{children}</Card>);
});

let content;

class McqForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      opened: false,
      Modelshow: false,
      collapseID: "collapse1",
      pathName : "",
      alreadyExist: null,
      parameters:[],
      upload: false
    };
    this.toggleBox = this.toggleBox.bind(this);
  }

  async componentDidMount() {
    const { MakeEdit, SetType, SetValue } = this.props;
    const { id, type } = this.props.match.params;
    initializeReactGA(this.props.location.pathname);

    let clonedParameters = [];
    let duplicateArray= Array.from(this.props.parameters);

    duplicateArray.map(element =>{
      let options = Array.from(element.options);
      let obj = {question: element.question, options: options};
      clonedParameters.push(obj);
      return null;
    });

    this.setState({pathName: this.props.location.pathname, parameters: clonedParameters });
    initializeReactGA(this.props.location.pathname);

    if (type) {
      SetType({
        type: type
      });
    }
    if (id) {
      // to find the parent tab to restrict the user to access interactive outside loree
      const parentTab = document.referrer;
      if(parentTab.includes("/canvas-get")){
        const user_id = localStorage.getItem("user_id");
        sessionStorage.setItem("user_id", user_id);
      } 

      if (this.props.match.params.user === undefined || this.props.match.params.user === "") {
        content = sessionStorage.getItem("user_id");
      } else {
        content = this.props.match.params.user;
      }
      
      const org_id = sessionStorage.getItem("org_id");
      if(this.props.match.params.orgId !== undefined && (org_id !== undefined || org_id !== "") && this.props.match.params.orgId !== org_id){
        sessionStorage.setItem("org_id", this.props.match.params.orgId);
      }

      let data = await getContent(id, content);
      if (data.message === "Unauthorized User or Invalid Content") return this.props.history.push("/401");
      let contentArr = [], parameters = JSON.parse(data.parameters);
      parameters.forEach((el, index) => {
        let question = (Object.keys(el));
        contentArr.push([`${question[0]}_${index}`, { options: [] }]);
        el.options.forEach((option) => {
          let value = Object.keys(option);
          contentArr.forEach(optionvalue => {
            optionvalue[1].options.push(`${value[0]}_${this.props.option_id}`);
            SetValue({
              num: index + 1,
              option_id: this.props.option_id + 2
            });
          });
        });
      });

      this.setState({alreadyExist: this.state.pathName.includes("duplicate") ? "* DUPLICATED INTERACTIVE - Please enter a new title" : null});
      MakeEdit({
        title: this.state.pathName.includes("duplicate") ? "" : data.title,
        parameters: parameters,
        contentArr: contentArr,
        component: data.type,
        optiontype: data.customisations.optionType,
        customtryagain: data.customisations.retry,
        checkedAnswer: data.customisations.checkAnswer,
        shuffle: data.customisations.shuffle,
        pbBgColor: data.customisations.pbBgColor ? data.customisations.pbBgColor : "#1D77CC",
        pbFontColor: data.customisations.pbFontColor ? data.customisations.pbFontColor : "#ffffff",
        quFontSize: data.customisations.quFontSize ? data.customisations.quFontSize : "16",
        quFontWeight: data.customisations.quFontWeight? data.customisations.quFontWeight : "Normal",
        opFontSize : data.customisations.opFontSize? data.customisations.opFontSize : "16",
        opFontWeight: data.customisations.opFontWeight ? data.customisations.opFontWeight: "Normal",
        bgColor: data.customisations.bgColor? data.customisations.bgColor: "#ffffff",
        opBgColor: data.customisations.opBgColor ? data.customisations.opBgColor: "#f8f9fa",
        borderSize: data.customisations.borderSize ? data.customisations.borderSize: "1", 
        borderStyle: data.customisations.borderStyle ? data.customisations.borderStyle : "solid", 
        borderColor:data.customisations.borderColor ? data.customisations.borderColor : "#000000",
        borderType:data.customisations.borderType ? data.customisations.borderType : "",
        buttonFontColor: data.customisations.buttonFontColor ? data.customisations.buttonFontColor : "#ffffff",
        buttonBgColor:data.customisations.buttonBgColor ? data.customisations.buttonBgColor : "#1D77CC",
        fontHeadings: data.customisations.fontHeadings ? data.customisations.fontHeadings : "Normal",
        fontFamily: data.customisations.fontFamily ? data.customisations.fontFamily : "Helvetica",
        fontSize: data.customisations.fontSize ? data.customisations.fontSize : "16",
        fontWeight: data.customisations.fontWeight ? data.customisations.fontWeight : "Normal",
        pbFontHeadings: data.customisations.pbFontHeadings ? data.customisations.pbFontHeadings : "Normal",
        pbFontFamily: data.customisations.pbFontFamily ? data.customisations.pbFontFamily : "Helvetica",
        pbFontSize: data.customisations.pbFontSize ? data.customisations.pbFontSize : "16",
        pbFontWeight: data.customisations.pbFontWeight ? data.customisations.pbFontWeight : "Normal",
        alignment: data.customisations.alignment ? data.customisations.alignment : "left",
        btnType: data.customisations.btnType ? data.customisations.btnType : "btn btn-square btn-dark",
        customPaddingtop: data.customisations.customPaddingtop ? data.customisations.customPaddingtop : "10",
        customPaddingleft: data.customisations.customPaddingleft ? data.customisations.customPaddingleft : "10",
        customPaddingbottom: data.customisations.customPaddingbottom ? data.customisations.customPaddingbottom : "10",
        customPaddingright: data.customisations.customPaddingright ? data.customisations.customPaddingright : "10"
      });
    }
  }


  //For customization block
  toggleBox() {
    const { opened } = this.state;
    this.setState({
      opened: !opened,
      collapseID: "collapse1"
    });
  }

  // value from main-title
  handleTitle = event => {
    const { errors, HandleTitle } = this.props;
    const { value } = event.target;
    let fail = errors;
    if (this.state.pathName.includes("/duplicate")){
      let data = this.props.location.state.contentList.filter(function(el) { return el.title === event.target.value;});
      this.setState({ 
        alreadyExist :  value === "" ? "* DUPLICATED INTERACTIVE - Please enter a new title" : data.length !== 0 ? 
          "* This title already exists. Please enter a new title" :null 
      });
    }
    if (value.length < 256) {
      HandleTitle({
        title: !value.trim() ? "" : value ,
        errors: fail
      });
    }
  }

  //value from the editor within the panel
  handleEditorQuestion = (i, value) => {
    const { errors, parameters, HandleChange } = this.props;
    let content = parameters;
    let fail = errors;
    // eslint-disable-next-line no-useless-escape
    let refinedValue = value.replace(/\>\s+\</g,"><br><");
    refinedValue = refinedValue.split("<p><br></p>");
    let finalValue="";
    refinedValue.forEach((data,index) =>{
      finalValue += refinedValue[index] !== "" ? refinedValue[index] : "";
    });
    if (value !== "<p><br></p>" || value !== "<h1><br></h1>" || value !== "<h2><br></h2>" || value !== "<h3><br></h3>" || value !== "<h4><br></h4>"
    || value !== "<h5><br></h5>" || value !== "<h6><br></h6>") {
      content[i] = { ...content[i], [inputType.question]: finalValue };
    }
    else {
      content[i] = { ...content[i], [inputType.question]: "" };
    }
    HandleChange({
      parameters: content,
      errors: fail
    });
  }


  handleEditorOption = (i, j, value) => {
    const { errors, parameters, HandleChange, contentArr } = this.props;
    let content = parameters;
    let fail = errors;
    // eslint-disable-next-line no-useless-escape
    let refinedValue = value.replace(/\>\s+\</g,"><br><");
    let optionData = refinedValue.split("<p><br></p>");
    let optionValue="";
    optionData.forEach((data,index) =>{
      optionValue += optionData[index] !== "" ? optionData[index] : "";
    });
    if (value !== "<p><br></p>" || value !== "<h1><br></h1>" || value !== "<h2><br></h2>" || value !== "<h3><br></h3>" || value !== "<h4><br></h4>"
     || value !== "<h5><br></h5>" || value !== "<h6><br></h6>") {
      delete fail[contentArr[i][j + 1]];
      content[i].options[j] = { ...content[i].options[j], [option.option]: optionValue };
    }
    else {
      content[i].options[j] = { ...content[i].options[j], [option.option]: "" };
    }
    HandleChange({
      parameters: content,
      errors: fail
    });
  }

  handleEditorMytip = (i, j, event) => {
    const { errors, parameters, HandleChange } = this.props;
    const { value } = event.target;
    let content = [...parameters];
    let fail = errors;
    if (value !== "<p><br></p>") {
      content[i].options[j] = { ...content[i].options[j], [option.mytip]: value };
    }
    else {
      content[i].options[j] = { ...content[i].options[j], [option.mytip]: "" };
    }
    HandleChange({
      parameters: content,
      errors: fail
    });
  }

  //To fetch checkbox value for options
  handleChecked = (i, j) => {
    const { isChecked, errors, parameters, HandleCheck } = this.props;
    let fail = errors;
    let content = parameters;
    let checkValue = HandleCheck({
      isChecked: !isChecked,
      errors: fail
    });
    content[i].options[j] = { ...content[i].options[j], [option.isChecked]: checkValue.isChecked };
  }
  //To fetch checkbox value for show tip
  handleShowTip = (i, j) => {
    const { isShowtip, parameters, HandleShowTip } = this.props;
    let content = parameters;
    let checkValue = HandleShowTip({
      isShowtip: !isShowtip,
    });
    content[i].options[j] = { ...content[i].options[j], [option.isShowtip]: checkValue.isShowtip };
  }

  //dynamically adding panel functionality
  addpanel = () => {
    const { addPanels } = this.props;
    addPanels({
      parameters: [...this.props.parameters, { "question": "", "options": [{ option: "", correct: "", mytip: "", check: "" }, { option: "", correct: "", mytip: "", check: "" }] }],
      contentArr: [...this.props.contentArr, [`question_${this.props.num}`, { options: [`option_${this.props.option_id}`, `option_${this.props.option_id + 1}`] }]],
      num: this.props.num + 1,
      option_id: this.props.option_id + 2
    });
  }
  //dynamically adding the option functionality
  addOption = (idx) => {
    const { addOption, parameters, contentArr } = this.props;
    const content = parameters;
    const contentArray = contentArr;
    content[idx].options = [...content[idx].options, { option: "", correct: "", mytip: "", check: "" }];
    contentArray[idx][1].options = [...contentArray[idx][1].options, `option_${this.props.option_id}`];
    addOption({
      parameters: content,
      contentArr: contentArray,
      option_id: this.props.option_id + 1
    });
  }

  //remove the selected panel
  removeClick = (index, element) => {
    const { errors, parameters, contentArr, RemoveCLick } = this.props;
    swal.fire({
      allowOutsideClick: false,
      title: "Are you sure you wish to remove this panel?",
      type: "question",
      showCancelButton: true,
      cancelButtonText: "Cancel",
    })
      .then((isConfirm) => {
        if (isConfirm.value) {
          let content = [...parameters];
          content.splice(index, 1);
          let contentArray = [...contentArr];
          contentArray.splice(index, 1);
          let errorObject = errors;
          delete errorObject[element[0]];
          delete errorObject[element[1]];
          //removes value if stored in error-object it is stored in error object,by submitting without value 
          RemoveCLick({
            parameters: content,
            contentArr: contentArray,
            errors: errorObject
          });
        }
      });
  };

  //remove the selected option
  removeOption = (index, idx) => {
    const { parameters, contentArr, RemoveOption } = this.props;
    swal.fire({
      allowOutsideClick: false,
      title: `Are you sure you want to delete option ${idx + 1}?`,
      type: "question",
      showCancelButton: true,
      cancelButtonText: "Cancel",
    })
      .then((isConfirm) => {
        if (isConfirm.value) {
          parameters[index].options.splice(idx, 1);
          contentArr[index][1].options.splice(idx, 1);
          //removes value if stored in error-object it is stored in error object,by submitting without value 
          RemoveOption({
            parameters: parameters,
            contentArr: contentArr,
          });
        }
        this.forceUpdate();
      });
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { RearangeArray, parameters, contentArr } = this.props;
    RearangeArray({
      contentArr: arrayMove(contentArr, oldIndex, newIndex),
      parameters: arrayMove(parameters, oldIndex, newIndex)
    });
  }

  modalToggle = (event) => {
    const { parameters, title, ErrorsFetch, contentArr } = this.props;
    let duplicateArray = [];
    event.preventDefault();
    let fail = validateForm(parameters, title, ErrorsFetch, contentArr, this.state.pathName);
    if (Object.keys(fail).length === 0) {
      if(this.props.shuffle){
        let clonedParameters = Array.from(parameters);
        clonedParameters.map(element => {
          let options = Array.from(element.options);
          options = shuffleOptions(options);
          let obj = {question: element.question, options: options};
          duplicateArray.push(obj);
          return null;
        });
      }
      this.setState({parameters: this.props.shuffle === true ? duplicateArray : parameters,  Modelshow: true });
    }
  }

  //functionality for Modalclose
  Modelclose = () => {
    const { parameters } = this.props;
    this.setState({ Modelshow: false }, () => {
      parameters.forEach((parameters_el) => {
        if (parameters_el.checked !== undefined && parameters_el.answerCorrect !== undefined) {
          delete parameters_el.answerCorrect;
          delete parameters_el.checked;
        }
      });
    });
  }

  //sumbit the form values to database and send response to preview page
  handleSubmit = async event => {
    const { parameters, title, ErrorsFetch, contentArr, optiontype, checkedAnswer, customresult, customtryagain, shuffle, pbBgColor, pbFontColor, quFontSize,
      quFontWeight, opFontSize, opFontWeight, bgColor, opBgColor, borderSize, borderStyle, borderColor, borderType, buttonFontColor, buttonBgColor,
      fontHeadings, fontFamily, fontSize, fontWeight, pbFontHeadings, pbFontFamily, pbFontSize, pbFontWeight, alignment, btnType, customPaddingbottom, 
      customPaddingleft, customPaddingtop, customPaddingright } = this.props;
    const { id } = this.props.match.params;
    let response = {};
    let user_id;
    if (this.props.match.params.user === undefined || this.props.match.params.user === "") {
      user_id = sessionStorage.getItem("user_id");
    } else {
      user_id = this.props.match.params.user;
    }
    event.preventDefault();
    let fail = validateForm(parameters, title, ErrorsFetch, contentArr, this.state.pathName);
    let dupicatetitle = fail.title === "* DUPLICATED INTERACTIVE - Please enter a new title"  ? delete fail["title"] : null;  

    if (Object.keys(fail).length === 0 && this.state.alreadyExist === null) {
      let data = {
        user_id: user_id,
        library_id: 4,
        organization_id: sessionStorage.getItem("org_id"),
        title: title,
        parameters: JSON.stringify(parameters),
        customisations: {
          "optionType": optiontype,
          "getResult": customresult,
          "retry": customtryagain,
          "checkAnswer": checkedAnswer,
          "shuffle": shuffle,
          "pbBgColor": pbBgColor,
          "pbFontColor": pbFontColor,
          "quFontSize": quFontSize,
          "quFontWeight": quFontWeight,
          "opFontSize" : opFontSize,
          "opFontWeight": opFontWeight,
          "bgColor": bgColor,
          "opBgColor": opBgColor,
          "borderSize": borderSize, 
          "borderStyle": borderStyle, 
          "borderColor":borderColor,
          "borderType":borderType,
          "buttonFontColor": buttonFontColor,
          "buttonBgColor": buttonBgColor,
          "fontHeadings": fontHeadings,
          "fontFamily": fontFamily,
          "fontSize": fontSize,
          "fontWeight":fontWeight,
          "pbFontHeadings": pbFontHeadings,
          "pbFontFamily": pbFontFamily,
          "pbFontSize": pbFontSize,
          "pbFontWeight": pbFontWeight,
          "alignment": alignment,
          "btnType": btnType,
          "customPaddingtop":customPaddingtop,
          "customPaddingleft":customPaddingleft,
          "customPaddingbottom":customPaddingbottom,
          "customPaddingright":customPaddingright
        },
        active: true,
        private: true
      };

      if (id) {
        const { pathName } = this.state;
        if (pathName.includes("duplicate") && dupicatetitle === null) {
          response = await createContent(data);
          swal.fire({
            allowOutsideClick: false,
            title: "Duplicated",
            text: "Content Duplicated Successfully.",
            icon: "success",
            showConfirmButton: true
          });
          this.props.history.push({
            pathname: `/contentview/multiplechoice/${this.props.match.params.user}/${response.id}`,
            state: {
              user: this.props.match.params.orgId,
              user_id: this.props.match.params.user
            }
          });
        } else{
          response.id = id;
          await editContent({
            id,
            body: data
          });
          swal.fire({
            allowOutsideClick: false,
            title: "Updated",
            text: "Content updated Successfully.",
            icon: "success",
            showConfirmButton: true
          });
          if (this.props.match.params.user === undefined || this.props.match.params.user === "") {
            this.props.history.push({
              pathname: `/create/multiplechoice/${response.id}`,
              state: {
                user: (this.props.location.state !== undefined) ? this.props.location.state.user : "",
                user_id: (this.props.location.state !== undefined) ? this.props.location.state.user_id : ""
              }
            });
          } else {
            this.props.history.push({
              pathname: `/contentview/multiplechoice/${this.props.match.params.user}/${response.id}`,
              state: {
                user: (this.props.location.state !== undefined) ? this.props.location.state.user : "",
                user_id: (this.props.location.state !== undefined) ? this.props.location.state.user_id : ""
              }
            });
          }
        }
      }
      else {
        response = await createContent(data);
        this.props.history.push({
          pathname: `/create/multiplechoice/${response.id}`,
          state: {
            user: (this.props.location.state !== undefined) ? this.props.location.state.user : "",
            user_id: (this.props.location.state !== undefined) ? this.props.location.state.user_id : ""
          }
        });
      }
    }
  }

  handleUpload = () => { // to show the loader while uploading the image in s3
    const { upload } = this.state;
    this.setState({upload: !upload});
  }

  render() { // users view area 
    const { errors, contentArr, parameters, title } = this.props;
    const { id } = this.props.match.params;
    const { opened, upload } = this.state;
    let pathName = this.props.location.pathname;

    //To display the customise and preview button based on the version
    let loreeVersion = sessionStorage.getItem("loreeVersion");
    let rowClass = (loreeVersion === "1") ? "flex-row-reverse" : "flex-row";
   
    if(id){
     
      //To display the customise and preview button based on the version
      if (loreeVersion === null) {
        sessionStorage.setItem("loreeVersion", this.props.match.params.version);
        loreeVersion = sessionStorage.getItem("loreeVersion");
        rowClass = (loreeVersion === "1") ? "flex-row-reverse" : "flex-row";
      } 

      // to find the parent tab to restrict the user to access interactive outside loree
      if(window.location !== window.parent.location){
        const user_id = localStorage.getItem("user_id");
        sessionStorage.setItem("user_id", user_id);
      }
    }
          
    if (sessionStorage.getItem("user_id") === null) return <Redirect to="/401" />;
    return (
      <React.Fragment>
        <Header user={localStorage.getItem("user")}
          user_id={sessionStorage.getItem("user_id")} 
          data={this.props}
        />
        <Container className="margin">
          <div className={`d-flex ${rowClass} my-3`}>
            <Button className="m-1 btn-pill mw-100" onClick={this.toggleBox} size="sm" variant="dark">
              Customize <i className={!opened ? "fa fa-plus" : "fa fa-minus"}></i>
            </Button>
            <Button className="m-1 btn-pill mw-100" disabled={upload} onClick={this.modalToggle} size="sm" variant="light">
              Preview
            </Button>
            <McqPreview content={this.props}
              parameters ={this.state.parameters}
              show={this.state.Modelshow}
              onHide={this.Modelclose}
            />
          </div>
          <Row>
            {opened && (loreeVersion === "2") && (
              <Customize content={this.props} />
            )}
            <Col>
              <Form onSubmit={this.handleSubmit} >
                <Card>
                  <Card.Header className="text-capitalize h4 font-weight-bold header">
                    Interactive Content Builder <span className="h4"> | </span><span style={{ color: "#0B099C" }}>Multiple choice question</span>
                  </Card.Header>
                  <Card.Body>
                    <Form.Group>
                      <Form.Label>My Interactive Title *</Form.Label>
                      <FormControl name="title" id="title" onChange={this.handleTitle} value={title} autoComplete="off" />
                      {pathName.includes("/duplicate") && this.state.alreadyExist ? <Form.Text className="text-danger">{this.state.alreadyExist}</Form.Text> : null}
                      {errors.title ? <Form.Text className="text-danger">{errors.title}</Form.Text> : null}
                    </Form.Group>
                    <SortableContainer onSortEnd={this.onSortEnd} onSortStart={(_, event) => event.preventDefault()} useDragHandle helperClass="SortableHelper">
                      {contentArr.map((panel, index) => {
                        return (
                          <SortableItem className="mt-3" id={index} key={index} panel={panel} index={index} sortIndex={index} content={
                            <React.Fragment>
                              <Card className="m-2" >
                                <Card.Header className="text-capitalize" key={index} id={index}>
                                  {(() => {
                                    return (contentArr.length > 1) ? <Button variant="outline-danger" size="sm" title="Remove item!" onClick={this.removeClick.bind(this, index, panel)} className="float-right close-btn">x</Button> : null;
                                  })()}
                                  <DragHandle value={`${index + 1}`} />
                                </Card.Header>
                                <Card.Body>
                                  <Form.Group>
                                    <Form.Label htmlFor={panel[0]}>Question</Form.Label>
                                    <Editor id={panel[0]} name={panel[0]} onChange={this.handleEditorQuestion.bind(this, index)} value={parameters[index]["question"]}  handleUpload = {this.handleUpload}/>
                                    {errors[panel[0]] ? <Form.Text className="text-danger">{errors[panel[0]]}</Form.Text> : null}
                                    {errors[`opt_${panel[0]}`] ? <Form.Text className="text-danger">{errors[`opt_${panel[0]}`]}</Form.Text> : null}
                                  </Form.Group>
                                  {panel[1].options.map((option, idx) => {
                                    if ((parameters[index].options[idx] === parameters[index] && id) || parameters[index].options[idx]) {
                                      return (
                                        <Form.Group key={idx} className="mb-0">
                                          <Form.Label className="mt-2" htmlFor={option}>Option {`${idx + 1}`}</Form.Label>
                                          {(() => {
                                            return (parameters[index].options.length > 2) ? <Button variant="outline-danger" size="sm" title="Remove option!" onClick={this.removeOption.bind(this, index, idx)} className="m-2 float-right close-btn">x</Button> : null;
                                          })()}
                                          <Editor id={option} name={option} onChange={this.handleEditorOption.bind(this, index, idx)} value={parameters[index].options[idx]["option"]} handleUpload = {this.handleUpload}/>
                                          {errors[option] ? <Form.Text className="text-danger">{errors[option]}</Form.Text> : null}
                                          <Form.Check id={option} type="checkbox" label="Correct" onChange={this.handleChecked.bind(this, index, idx)} checked={parameters[index].options[idx]["correct"]} />
                                          <Form.Label>Tips</Form.Label>
                                          <FormControl id={`mytip_${idx}`} name={`mytip_${idx}`} type='text' as="textarea" onChange={this.handleEditorMytip.bind(this, index, idx)} value={parameters[index].options[idx]["mytip"]} />
                                          <Form.Check id={option} name={option} type="checkbox" label="Show Tip" onChange={this.handleShowTip.bind(this, index, idx)} checked={parameters[index].options[idx]["check"]} />
                                        </Form.Group>
                                      );
                                    }
                                    return null;
                                  })}
                                  {(() => {
                                    let addOption = [];
                                    if (panel[1].options.length < 100) {
                                      addOption.push(
                                        <Button key="addPanel" variant="outline-secondary" className="mt-3" onClick={this.addOption.bind(this, index)} size="sm">Add Option</Button>
                                      );
                                      return addOption;
                                    }
                                  })()}
                                </Card.Body>
                              </Card>
                            </React.Fragment>
                          }
                          />
                        );
                      })}
                    </SortableContainer>
                    {(() => {
                      let addPanel = [];
                      if (contentArr.length < 100) {
                        addPanel.push(
                          <Button key="addPanel" variant="outline-secondary" className="mt-3" onClick={this.addpanel} size="sm">Add Question</Button>
                        );
                        return addPanel;
                      }
                    })()}
                  </Card.Body>
                  <Card.Footer>
                    <Button className="btn btn-dark btn-sm btn-pill" disabled={upload}  type="submit" onClick={this.handleSubmit}>
                      {(id && pathName.includes("/duplicate")) ? "Create" : id ? "Update" :"Create"}
                    </Button>
                  </Card.Footer>
                </Card>
              </Form>
            </Col>
            {/* customization block */}
            {opened && (loreeVersion === "1") && (
              <Customize content={this.props} />
            )}
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

//state from redux
const mapStateToProps = ({ mcqform }) => ({
  num: mcqform.num,
  option_id: mcqform.option_id,
  contentArr: mcqform.contentArr,
  title: mcqform.title,
  parameters: mcqform.parameters,
  errors: mcqform.errors,
  isChecked: mcqform.isChecked,
  isShowtip: mcqform.isShowtip,
  customresult: mcqform.customresult,
  optiontype: mcqform.optiontype,
  checkedAnswer: mcqform.checkedAnswer,
  customtryagain: mcqform.customtryagain,
  shuffle: mcqform.shuffle,
  pbBgColor: mcqform.pbBgColor,
  pbFontColor: mcqform.pbFontColor,
  quFontSize: mcqform.quFontSize,
  quFontWeight: mcqform.quFontWeight,
  opFontSize : mcqform.opFontSize,
  opFontWeight: mcqform.opFontWeight,
  bgColor: mcqform.bgColor,
  opBgColor: mcqform.opBgColor,
  borderSize: mcqform.borderSize, 
  borderStyle: mcqform.borderStyle, 
  borderColor:mcqform.borderColor,
  borderType:mcqform.borderType,
  buttonFontColor: mcqform.buttonFontColor,
  buttonBgColor: mcqform.buttonBgColor,
  fontFamily: mcqform.fontFamily,
  fontSize: mcqform.fontSize,
  fontWeight: mcqform.fontWeight,
  pbFontHeadings: mcqform.pbFontHeadings,
  pbFontFamily: mcqform.pbFontFamily,
  pbFontSize: mcqform.pbFontSize,
  pbFontWeight: mcqform.pbFontWeight,
  alignment : mcqform.alignment,
  btnType: mcqform.btnType,
  customPaddingtop:mcqform.customPaddingtop,
  customPaddingleft:mcqform.customPaddingleft,
  customPaddingbottom:mcqform.customPaddingbottom,
  customPaddingright:mcqform.customPaddingright
});

//props to redux
const mapDispatchToProps = dispatch => ({
  addPanels: form => dispatch(addPanel(form)),
  addOption: form => dispatch(addOption(form)),
  HandleChange: form => dispatch(handleChange(form)),
  HandleTitle: form => dispatch(handleTitle(form)),
  RemoveCLick: form => dispatch(removeClick(form)),
  RemoveOption: form => dispatch(removeOption(form)),
  RearangeArray: form => dispatch(rearangeArray(form)),
  HandleCheck: data => dispatch(setCheck(data)),
  HandleShowTip: data => dispatch(setShowTip(data)),
  MakeEdit: form => dispatch(makeEdit(form)),
  SetType: form => dispatch(setType(form)),
  SetValue: form => dispatch(setValue(form)),
  ErrorsFetch: form => dispatch(errorsFetch(form)),
});

//connecting form with redux
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(McqForm);

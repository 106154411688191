/* eslint-disable no-undefined */
/* eslint-disable no-useless-concat */
import React from "react";
import { Redirect } from "react-router-dom";
import "../../assets/scss/multipleChoice.scss";
import { Container, Button, Row, Col } from "react-bootstrap";
import { putItems } from "../../redux/components/reducer.action";
import { fetchContent, viewContent } from "../../middleware/api";
import { initializeReactGA } from "../../middleware/googleAnalytics";
import { shuffleOptions } from "../Interactives/shuffle";
import Mytips from "../multipleChoice/mytips";
import { connect } from "react-redux";
import Header from "../layouts/navbar";
import PostHeightIframe from "../../middleware/sendHeight";
import ReactHtmlParser from "react-html-parser";

let Id, mouseover, buttonstyle, buttonUnhover, buttonHover, alignment, btnType ;

const QuizProgress = props => {
  const pbFontFamily = props.customisation.pbFontFamily ? props.customisation.pbFontFamily : "Helvetica",
    pbFontSize = props.customisation.pbFontSize ? props.customisation.pbFontSize : "16",
    pbFontWeight = props.customisation.pbFontWeight ? props.customisation.pbFontWeight : "Normal",
    pbFontHeading = props.customisation.pbFontHeadings ? props.customisation.pbFontHeadings : "Normal";
  return (
    <div className={`progress ${pbFontHeading}`} style={{fontSize:`${pbFontSize}px`,
      fontWeight: `${pbFontWeight}`, fontFamily: `${pbFontFamily}`}}>
      <p className="counter" style={{color: props.pbFontColor}}>
        <span>
          Question {props.currentQuestion + 1} of {props.questionLength}
        </span>
      </p>
      <div
        className="progress-bar"
        style={{
          width:
            ((props.currentQuestion + 1) / props.questionLength) * 100 + "%",
          background: props.pbBgColor,
        }}
      ></div>
    </div>
  );
};
const Results = props => {
  return (
    <React.Fragment>
      {props.location === `/multiplechoice/${props.contentid}` ? (
        ""
      ) : (
        <Header user={props.user} user_id={props.user_id} data={props.data}/>
      )}
      <section className="quiz">
        <div className=" margin results fade-in">
          <h1>
            Your score: {((props.correct / props.questionLength) * 100).toFixed()}
            %
          </h1>
          {props.customisation.retry ? (
            <>
              <div className={"text-" + `${alignment}`}>
                {mouseover ? (
                  <Button
                    className={`${btnType}`}
                    style={props.hover ? buttonHover : buttonUnhover}
                    onMouseEnter={ props.hoverOn.bind(this, "tryagain")}
                    onMouseLeave={props.hoverOff.bind(this, "tryagain")}
                    type="button"
                    onClick={props.startOver}
                  >
                    Try again <i className="fas fa-redo"></i>
                  </Button>
                ) : (
                  <Button
                    className={`${btnType}`}
                    style = {buttonstyle}
                    type="button"
                    onClick={props.startOver}
                  >
                    Try again <i className="fas fa-redo"></i>
                  </Button>
                )}
              </div>
            </>
          ) : null}
        </div>
      </section>
      {(props.data.location.pathname === `/multiplechoice/${Id}`) ? PostHeightIframe(props.type) : ""}
    </React.Fragment>
  );
};

let content, response;

class Quiz extends React.Component {
  //state for the multiplechoice
  constructor(props) {
    super(props);
    this.updateAnswer = this.updateAnswer.bind(this);
    this.checkAnswer = this.checkAnswer.bind(this);
    this.nextQuestion = this.nextQuestion.bind(this);
    this.getResults = this.getResults.bind(this);
    this.startOver = this.startOver.bind(this);
    this.state = {
      hover: false,
      hoverNext: false,
      hoverGetRes: false,
      hoverTryagain: false,
      currentQuestion: 0,
      libraryId: null,
      correct: 0,
      inProgress: true,
      checkAnswered: true,
      mytipsprops: "",
      questions: [
        {
          question: "",
          options: [
            {
              option: "",
              correct: false,
              tips: ""
            },
            {
              option: "",
              correct: false,
              tips: ""
            },
            {
              option: "",
              correct: false,
              tips: ""
            },
          ],

        },
      ],
    };
  }
  //record whether the question was answered correctly
  updateAnswer(e) {
    let answerValue = e.target.value;
    let { customisations } = this.props;
    this.setState(prevState => {
      let stateToUpdate = prevState.questions;
      stateToUpdate[prevState.currentQuestion].answerCorrect = answerValue;
      if (customisations.optionType === "checkbox") {
        let CheckboxChecked =
          document.getElementById("checkbox-0").checked ||
          document.getElementById("checkbox-1").checked;

        // eslint-disable-next-line no-unused-expressions
        customisations.checkAnswer
          ? this.setBtnDisabled("btn", CheckboxChecked)
          : this.setBtnDisabled("nextBtn", CheckboxChecked);
      }
      return { questions: stateToUpdate, checkAnswered: false };
    });
  }

  setBtnDisabled(btnName, CheckboxChecked) {
    document.getElementById(`${btnName}`).disabled = !CheckboxChecked;
  }
  //display to the user whether their answer is correct
  checkAnswer() {
    this.setState(prevState => {
      let stateToUpdate = prevState.questions;
      stateToUpdate[prevState.currentQuestion].checked = true;
      return { questions: stateToUpdate };
    });
    if(this.props.location.pathname === `/multiplechoice/${Id}`) PostHeightIframe();
  }
  //advance to the next question
  nextQuestion() {
    this.setState(
      prevState => {
        let stateToUpdate = prevState.currentQuestion;
        return { currentQuestion: stateToUpdate + 1, checkAnswered: true, hover: false  };
      },
      () => {
        this.radioRef.current.reset();
      },
    );
    if(this.props.location.pathname === `/multiplechoice/${Id}`) PostHeightIframe();
  }
  //loop through questions and calculate the number right
  getResults() {
    let correct = this.state.correct;
    this.state.questions.forEach((item, index) => {
      if (item.answerCorrect === "true") {
        ++correct;
      }
      if (index === this.state.questions.length - 1) {
        this.setState({
          correct: correct,
          inProgress: false,
          checkAnswered: true,
          hoverGetRes: false
        });
      }
    });
    if(this.props.location.pathname === `/multiplechoice/${Id}`) PostHeightIframe(this.state.libraryId);
  }
  //Edit the created component
  handleEdit = () => {
    const { id } = this.props.match.params;
    let path;
    if (this.props.match.params.user === undefined) {
      path = "/editmcq/multiplechoice";
    } else {
      path = `/multiplechoice/edit/${this.props.match.params.user}`;
    }
    if (id) {
      this.props.history.push({
        pathname: `${path}/${id}/${sessionStorage.getItem("org_id")}/${sessionStorage.getItem("loreeVersion")}`,
        state: {
          user:
            this.props.location.state !== undefined
              ? this.props.location.state.user
              : "",
          user_id:
            this.props.location.state !== undefined
              ? this.props.location.state.user_id
              : "",
        },
      });
    }
  };
  // Once customization is added this feature will be available (Try again feature)
  startOver() {
    // reset form and state back to its original value
    this.setState(prevState => {
      let questionsToUpdate = prevState.questions;
      questionsToUpdate.forEach(item => {
        // clear answers from previous state
        delete item.answerCorrect;
        delete item.checked;
      });
      PostHeightIframe();
      // Shuffle the options if isShuffle true
      if (this.state.isShuffle === true && questionsToUpdate[0]) {
        for (let i = 0; i < questionsToUpdate.length; i++) {
          questionsToUpdate[i].options = shuffleOptions(
            questionsToUpdate[i].options,
          );
        }
      }
      return {
        inProgress: true,
        correct: 0,
        currentQuestion: 0,
        checkAnswered: true,
        questions: questionsToUpdate,
        hoverTryagain: false,
        hover: false
      };
    });
    if(this.props.location.pathname === `/multiplechoice/${Id}`) PostHeightIframe();
  }

  //Getting data from the Redux-state
  async componentDidMount() {
    const { PutItems } = this.props;
    const { id } = this.props.match.params;
    if (
      this.props.location.pathname === `Accordion/${id}` ||
      `accordion/${id}`
    ) {
      response = await viewContent(id);
    } else {
      if (
        this.props.match.params.user === undefined ||
        this.props.match.params.user === ""
      ) {
        content = sessionStorage.getItem("user_id");
      } else {
        content = this.props.match.params.user;
      }
      response = await fetchContent(id, content);
    }
    if (!response.message && response.library_id === 4) {

      //To check the tracking id of GA
      if(this.props.location.pathname !== `/multiplechoice/${id}` ) initializeReactGA(this.props.location.pathname);

      this.setState({libraryId: response.library_id});
      PutItems({
        title: response.title,
        parameters: JSON.parse(response.parameters),
        customisations: response.customisations,
      });
      let customisations = response.customisations;
      let customShuffle = customisations.shuffle;
      let shuffleResponse = JSON.parse(response.parameters);
      let finalParams = JSON.parse(response.parameters);

      if (customShuffle === true) {
        for (let i = 0; i < shuffleResponse.length; i++) {
          finalParams[i].options = shuffleOptions(finalParams[i].options);

          for (let m of finalParams[i].options) {
            this.setState({
              mytipsprops: m.mytip
            });
          }
          this.setState({
            questions: finalParams,
            isShuffle: customShuffle,
          });
        }
      } else {
        this.setState({
          questions: shuffleResponse,
        });
      }
      //since we're re-using the same form across questions,
      //create a ref to it so we can clear its state after a question is answered
      this.radioRef = React.createRef();
    } else {
      this.props.history.push("/*");
    }
    if(this.props.location.pathname === `/multiplechoice/${id}` ) PostHeightIframe();
  }


  //mouse cursor in button
  // eslint-disable-next-line no-unused-vars
  hoverOn = (type, e) => {
    if (type === "checkAns"){ this.setState({ hover: true }); }
    else if (type === "next") { this.setState({hoverNext: true}); }
    else if (type === "result") { this.setState({hoverGetRes: true}); }
    else { this.setState({hoverTryagain: true}); }
  };

 //mouse cursor leave from button
 // eslint-disable-next-line no-unused-vars
 hoverOff = (type, e) => {
   if (type === "checkAns"){ this.setState({ hover: false }); }
   else if (type === "next") { this.setState({hoverNext: false}); }
   else if (type === "result") { this.setState({hoverGetRes: false}); }
   else { this.setState({hoverTryagain: false}); }
 };

 render() {
   const { customisations } = this.props;
   const { id } = this.props.match.params;
   const viewpage = this.props.location.pathname;
   let Answerbtn = null;
   let correctanswer = null;
   Id = this.props.match.params.id;
   mouseover = (customisations.btnType === "btn btn-outline-dark") ? true : false;
   btnType = customisations.btnType;
   alignment = customisations.alignment;

   // setting the default color for the older interactives
   const pbBgColor = customisations.pbBgColor ? customisations.pbBgColor : "#1D77CC",
     pbFontColor = customisations.pbFontColor ? customisations.pbFontColor : "#ffffff",
     quFontSize = customisations.quFontSize ? customisations.quFontSize : "16",
     quFontWeight =  customisations.quFontWeight? customisations.quFontWeight : "Normal",
     opFontSize  =  customisations.opFontSize? customisations.opFontSize : "16",
     opFontWeight = customisations.opFontWeight ? customisations.opFontWeight: "Normal",
     bgColor =  customisations.bgColor? customisations.bgColor: "#ffffff",
     opBgColor = customisations.opBgColor ? customisations.opBgColor: "#f8f9fa",
     borderSize = customisations.borderSize ? customisations.borderSize: "", 
     borderStyle = customisations.borderStyle ? customisations.borderStyle : "", 
     borderColor = customisations.borderColor ? customisations.borderColor : "#000000",
     borderType = customisations.borderType ? customisations.borderType : "",
     buttonFontColor = customisations.buttonFontColor ? customisations.buttonFontColor : "#ffffff",
     buttonBgColor = customisations.buttonBgColor ? customisations.buttonBgColor : "#1D77CC",
     fontFamily = customisations.fontFamily ? customisations.fontFamily : "Helvetica",
     fontSize = customisations.fontSize ? customisations.fontSize : "16",
     fontWeight = customisations.fontWeight ? customisations.fontWeight : "Normal";

   buttonstyle = {
     background: `${customisations.buttonBgColor}`,
     fontFamily: `${fontFamily}`,
     fontSize: `${fontSize}px`,
     fontWeight: fontWeight,
     color: `${buttonFontColor}`,
     border: "2px solid" + `${customisations.buttonBgColor}`,
     paddingTop: customisations.customPaddingtop ? `${customisations.customPaddingtop}px` : 10,
     paddingLeft: customisations.customPaddingleft ? `${customisations.customPaddingleft}px` : 10,
     paddingBottom: customisations.customPaddingbottom ? `${customisations.customPaddingbottom}px` : 10,
     paddingRight:  customisations.customPaddingright ? `${customisations.customPaddingright}px` : 10
   };
  
   buttonUnhover = {
     background: "white",
     color:  buttonFontColor === "#ffffff" ? "#000000" : buttonFontColor,
     fontFamily: `${customisations.fontFamily}`,
     fontSize: `${customisations.fontSize}px`,
     fontWeight: fontWeight,
     border: "2px solid" + `${buttonBgColor}`,
     paddingTop: customisations.customPaddingtop ? `${customisations.customPaddingtop}px` : 10,
     paddingLeft: customisations.customPaddingleft ? `${customisations.customPaddingleft}px` : 10,
     paddingBottom: customisations.customPaddingbottom ? `${customisations.customPaddingbottom}px` : 10,
     paddingRight: customisations.customPaddingright ? `${customisations.customPaddingright}px`: 10
   };
  
   buttonHover = {
     background: `${customisations.buttonBgColor}`,
     fontSize: `${customisations.fontSize}px`,
     border: "2px solid" + `${buttonBgColor}`,
     fontFamily: `${customisations.fontFamily}`,
     fontWeight: fontWeight,
     color: buttonFontColor,
     paddingTop: customisations.customPaddingtop ?  `${customisations.customPaddingtop}px` : 10,
     paddingLeft: customisations.customPaddingleft ? `${customisations.customPaddingleft}px` : 10,
     paddingBottom: customisations.customPaddingbottom ? `${customisations.customPaddingbottom}px` : 10,
     paddingRight: customisations.customPaddingright ?  `${customisations.customPaddingright}px` : 10
   };


   const borderleftStyle = (borderType === "border-left border-dark") ? true : false;

   const borderrightStyle = (borderType === "border-right border-dark") ? true : false;

   const bordertopStyle = (borderType === "border-top border-dark") ? true : false;  

   const borderbottomStyle = (borderType === "border-bottom border-dark") ? true : false;  

   const borderfullStyle = (borderType === "border border-dark") ? true : false; 

   const mcqborderleftStyle = {
     borderLeft: `${borderSize}px ` + `${borderStyle} ` + `${borderColor}`
   };

   const mcqborderrightStyle = {
     borderRight: `${borderSize}px ` + `${borderStyle} ` + `${borderColor}`
   };

   const mcqbordertopStyle = {
     borderTop: `${customisations.borderSize}px ` + `${customisations.borderStyle} ` + `${customisations.borderColor}`
   };

   const mcqborderbottomtStyle = {
     borderBottom: `${customisations.borderSize}px ` + `${customisations.borderStyle} ` + `${customisations.borderColor}`
   };

   const mcqborderfullStyle = {
     border: `${customisations.borderSize}px ` + `${customisations.borderStyle} ` + `${customisations.borderColor}`
   };

   //For displaying the score
   if (!this.state.inProgress) {
     return (
       <Results
         user={localStorage.getItem("user")}
         user_id={sessionStorage.getItem("user_id")}
         data={this.props}
         contentid={id}
         style = {{background: buttonBgColor, color: buttonFontColor}}
         correct={this.state.correct}
         questionLength={this.state.questions.length}
         customisation = {customisations}
         hover={this.state.hoverTryagain}
         startOver={this.startOver}
         hoverOn = {this.hoverOn.bind(this, "tryagain")}
         hoverOff = {this.hoverOff.bind(this, "tryagain")}
         location={this.props.location.pathname}
         type = {this.state.libraryId}
       />
     );
   }
   if (
     customisations.optionType === "checkbox" &&
      customisations.checkAnswer === true
   ) {
     Answerbtn = (
       <>
         <div className={"text-" + `${customisations.alignment}`}>
           {mouseover ? (
             <Button
               className={`${customisations.btnType}`}
               style={this.state.hover ? buttonHover : buttonUnhover}
               key="btn"
               id="btn"
               onMouseEnter={this.hoverOn.bind(this, "checkAns")}
               onMouseLeave={this.hoverOff.bind(this, "checkAns")}
               onClick={this.checkAnswer}
               disabled={this.state.checkAnswered}
             >
                  Check answer
             </Button>
           ) : (
             <Button
               className={`${customisations.btnType}`}
               style = {buttonstyle}
               key="btn"
               id="btn"
               type="button"
               onClick={this.checkAnswer}
               disabled={this.state.checkAnswered}
             >
                  Check answer
             </Button>
           )}
         </div>
       </>
     );
   } else if (
     customisations.optionType === "radio" &&
      customisations.checkAnswer === true
   ) {
     Answerbtn = (
       <>
         <div className={"text-" + `${customisations.alignment}`}>
           {mouseover ? (
             <Button
               className={`${customisations.btnType}`}
               style={this.state.hover ? buttonHover : buttonUnhover}
               onMouseEnter={this.hoverOn.bind(this, "checkAns")}
               onMouseLeave={this.hoverOff.bind(this, "checkAns")}
               type="button"
               onClick={this.checkAnswer}
               key="disablebtn"
               disabled={
                 !(
                   "answerCorrect" in
                      this.state.questions[this.state.currentQuestion]
                 )
               }
             >
                Check answer
             </Button>
           ) : (
             <Button
               className={`${customisations.btnType}`}
               style = {buttonstyle}
               type="button"
               onClick={this.checkAnswer}
               key="disablebtn"
               disabled={
                 !(
                   "answerCorrect" in
                      this.state.questions[this.state.currentQuestion]
                 )
               }
             >
                Check answer
             </Button>
           )}
         </div>
       </>
     );
   }
   if (customisations.checkAnswer === false) {
     correctanswer = (
       <div className="bottom">
         {(() => {
           if (
             !this.state.questions[this.state.currentQuestion].checked &&
              this.state.currentQuestion + 1 < this.state.questions.length
           )
             return (
               <>
                 <div className={"text-" + `${customisations.alignment}`}>
                   {mouseover ? (
                     <Button
                       className={`${customisations.btnType}`}
                       style={this.state.hoverNext ? buttonHover : buttonUnhover}
                       onMouseEnter={this.hoverOn.bind(this, "next")}
                       onMouseLeave={this.hoverOff.bind(this, "next")}
                       type="button"
                       onClick={this.nextQuestion}
                       id="nextBtn"
                       disabled={this.state.checkAnswered}
                     >
                       Next <i className="fa fa-arrow-right"></i>
                     </Button>
                   ) : (
                     <Button
                       className={`${customisations.btnType}`}
                       style = {buttonstyle}
                       type="button"
                       onClick={this.nextQuestion}
                       id="nextBtn"
                       disabled={this.state.checkAnswered}
                     >
                       Next <i className="fa fa-arrow-right"></i>
                     </Button>
                   )}
                 </div>
               </>
             );
         })()}
         {(() => {
           if (
             this.state.currentQuestion + 1 ===
              this.state.questions.length
           ) {
             return (
               <>
                 <div className={"text-" + `${customisations.alignment}`}>
                   {mouseover ? (
                     <Button
                       className={`${customisations.btnType} get-results pulse`}
                       style={this.state.hoverGetRes ? buttonHover : buttonUnhover}
                       onMouseEnter={this.hoverOn.bind(this, "result")}
                       onMouseLeave={this.hoverOff.bind(this, "result")}
                       onClick={this.getResults}
                       disabled={this.state.checkAnswered}
                     >
                        Get Results
                     </Button>
                   ) : (
                     <Button
                       className={`${customisations.btnType} get-results pulse`}
                       style = {buttonstyle}
                       type="button"
                       id="nextBtn"
                       onClick={this.getResults}
                       disabled={this.state.checkAnswered}
                     >
                        Get Results
                     </Button>
                   )}
                 </div>
               </>
             );
           }
         })()}
       </div>
     );
   } else if (customisations.checkAnswer === true) {
     correctanswer = (
       <div className="bottom">
         {!this.state.questions[this.state.currentQuestion].checked && [
           Answerbtn,
         ]}
         {(() => {
           if (
             this.state.currentQuestion + 1 < this.state.questions.length &&
              this.state.questions[this.state.currentQuestion].checked
           )
             return (
               <>
                 <div className={"text-" + `${customisations.alignment}`}>
                   {mouseover ? (
                     <Button
                       className={`${customisations.btnType} fade-in next`}
                       style={this.state.hoverNext ? buttonHover : buttonUnhover}
                       onMouseEnter={this.hoverOn.bind(this, "next")}
                       onMouseLeave={this.hoverOff.bind(this, "next")}
                       type="button"
                       onClick={this.nextQuestion}
                       id="nextBtn"
                       disabled={this.state.checkAnswered}
                     >
                     Next <i className="fa fa-arrow-right"></i>
                     </Button>
                   ) : (
                     <Button
                       className={`${customisations.btnType}`}
                       style = {buttonstyle}
                       type="button"
                       onClick={this.nextQuestion}
                       id="nextBtn"
                       disabled={this.state.checkAnswered}
                     >
                     Next <i className="fa fa-arrow-right"></i>
                     </Button>
                   )}
                 </div>
               </>
             );
         })()}

         {(() => {
           if (
             this.state.currentQuestion + 1 === this.state.questions.length &&
              this.state.questions[this.state.currentQuestion].checked
           ) {
             return (
               <>
                 <div className={"text-" + `${customisations.alignment}`}>
                   {mouseover ? (
                     <Button
                       className={`${customisations.btnType} get-results pulse`}
                       style={this.state.hoverGetRes ? buttonHover : buttonUnhover}
                       onMouseEnter={this.hoverOn.bind(this, "result")}
                       onMouseLeave={this.hoverOff.bind(this, "result")}
                       onClick={this.getResults}
                       disabled={this.state.checkAnswered}
                     >
                      Get Results
                     </Button>
                   ) : (
                     <Button
                       className={`${customisations.btnType} get-results pulse`}
                       style = {buttonstyle}
                       type="button"
                       id="nextBtn"
                       onClick={this.getResults}
                       disabled={this.state.checkAnswered}
                     >
                      Get Results
                     </Button>
                   )}
                 </div>
               </>
             );
           }
         })()}
       </div>
     );
   }
   if (viewpage === `/multiplechoice/${id}`) {
     return (
       <React.Fragment>
         <Container
           className={
             !this.state.questions[this.state.currentQuestion].question
               ? "hidden"
               : ""
           }
         >
           <section className="quiz fade-in mt-2" aria-live="polite" style={{background: bgColor, padding: "15px"}}>
             <QuizProgress
               currentQuestion={this.state.currentQuestion}
               questionLength={this.state.questions.length}
               pbBgColor = {pbBgColor}
               pbFontColor={pbFontColor}
               customisation = {customisations}
             />
             <div style = {borderleftStyle ? mcqborderleftStyle : (borderrightStyle) ? mcqborderrightStyle : (bordertopStyle) 
               ? mcqbordertopStyle : (borderbottomStyle) ? mcqborderbottomtStyle : (borderfullStyle) ? mcqborderfullStyle : null }>
               <div className="p-3 question-container">
                 <div className="question" style={{fontSize: `${quFontSize}px `, fontWeight: `${quFontWeight}` }}>
                   {ReactHtmlParser(this.state.questions[this.state.currentQuestion].question)}
                 </div>
                 <form ref={this.radioRef}>
                   {this.state.questions[
                     this.state.currentQuestion
                   ].options.map((item, index) => {
                     return (
                       <div
                         key={index}
                         className={
                           "option" +
                            (this.state.questions[this.state.currentQuestion]
                              .checked && !item.correct
                              ? " dim"
                              : "") +
                            (this.state.questions[this.state.currentQuestion]
                              .checked && item.correct
                              ? " correct"
                              : "")
                         }
                         style={{background: opBgColor}}
                       >
                         <input
                           id={`${customisations.optionType}-` + index}
                           onClick={this.updateAnswer}
                           type={customisations.optionType}
                           name="option"
                           defaultValue={item.correct}
                           disabled={
                             this.state.questions[this.state.currentQuestion]
                               .checked
                           }
                         />
                         <label 
                           htmlFor={`${customisations.optionType}-` + index}
                         >
                           <div className="optionAlignment"
                             style={{fontSize: `${opFontSize}px `, fontWeight: `${opFontWeight}`}}>
                             {ReactHtmlParser(item.option)}
                           </div>
                         </label>
                         {(item.check === true) ?
                           <Mytips tip={item.mytip} /> : ""}
                       </div>
                     );
                   })}
                 </form>
               </div>
               {correctanswer}
             </div>
           </section>
         </Container>
       </React.Fragment>
     );
   } else if (
     viewpage === `/create/multiplechoice/${id}` ||
      `contentview/multiplechoice/${this.props.match.params.user}/${id}`
   ) {
     if (this.props.location.state !== undefined) {
       return (
         <React.Fragment>
           <Header
             user={localStorage.getItem("user")}
             user_id={sessionStorage.getItem("user_id")}
             data={this.props}
           />
           <Container
             className={`margin
              ${!this.state.questions[this.state.currentQuestion].question
           ? "hidden"
           : ""}
              `}
           >
             <Row className="d-flex justify-content-between mt-3">
               <Col style={{wordBreak:"break-word"}}>
                 <h2>{this.props.title}</h2>
               </Col>
               <Col xs lg="1">
                 <Button className="btn btn-dark" onClick={this.handleEdit}>
                    Edit
                 </Button>
               </Col>
             </Row>
             <section className="quiz fade-in mt-2" aria-live="polite"style={{background: customisations.bgColor, padding: "15px"}}>
               <QuizProgress
                 currentQuestion={this.state.currentQuestion}
                 questionLength={this.state.questions.length}
                 pbBgColor = {pbBgColor}
                 pbFontColor={pbFontColor}
                 customisation = {customisations}
               />
               <div style = {borderleftStyle ? mcqborderleftStyle : (borderrightStyle) ? mcqborderrightStyle : (bordertopStyle) 
                 ? mcqbordertopStyle : (borderbottomStyle) ? mcqborderbottomtStyle : (borderfullStyle) ? mcqborderfullStyle : null }>
                 <div className="p-3 question-container">
                   <div className="question" style={{fontSize: `${customisations.quFontSize}px `, fontWeight: `${customisations.quFontWeight}` }}>
                     {ReactHtmlParser(this.state.questions[this.state.currentQuestion].question)}
                   </div>
                   <form ref={this.radioRef}>
                     {this.state.questions[
                       this.state.currentQuestion
                     ].options.map((item, index) => {
                       return (
                         <div
                           key={index}
                           className={
                             "option" +
                              (this.state.questions[this.state.currentQuestion]
                                .checked && !item.correct
                                ? " dim"
                                : "") +
                              (this.state.questions[this.state.currentQuestion]
                                .checked && item.correct
                                ? " correct"
                                : "")
                           }
                           style={{background: opBgColor}}
                         >
                           <input
                             id={`${customisations.optionType}-` + index}
                             onClick={this.updateAnswer}
                             type={customisations.optionType}
                             name="option"
                             defaultValue={item.correct}
                             disabled={
                               this.state.questions[this.state.currentQuestion]
                                 .checked
                             }
                           />
                           <label 
                             htmlFor={`${customisations.optionType}-` + index}
                           >
                             <div className="optionAlignment"
                               style={{fontSize: `${opFontSize}px `, fontWeight: `${opFontWeight}`}}>
                               {ReactHtmlParser(item.option)}
                             </div>
                           </label>
                           {(item.check === true) ?
                             <Mytips tip={item.mytip}/> : ""}
                         </div>

                       );
                     })}
                   </form>


                 </div>
                 {correctanswer}
               </div>
             </section>
           </Container>
         </React.Fragment>
       );
     } else {
       return <Redirect to="/401" />;
     }
   }
 }
}
//state from redux
const mapStateToProps = ({ component }) => ({
  title: component.title,
  parameters: component.parameters,
  currentQuestion: component.currentQuestion,
  customisations: component.customisations,
});
//props to redux
const mapDispatchToProps = dispatch => ({
  PutItems: component => dispatch(putItems(component)),
});
//connecting form with redux
export default connect(mapStateToProps, mapDispatchToProps)(Quiz);

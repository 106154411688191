import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Form, FormControl, Card, Table, Container } from "react-bootstrap";
import { getContents, deleteContent, toggleActive } from "../middleware/api";
import { initializeReactGA } from "../middleware/googleAnalytics";
import Header from "./layouts/navbar";
import swal from "sweetalert2";
import "./form/form.scss";
import CircleLoader from "./loader/loader";
import ReactPaginate from "react-paginate";
class ContentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentList: [],
      orgtabledata:[],
      filterData: [],
      searchText: "",
      loading: true,
      offset :0,
      parPage: 10,
      currentpage:0,
      count : 0
    };
    this.handlePageClick = this.handlePageClick.bind(this);
  }
  componentDidMount = () => {
    
    //To check the tracking id of GA
    initializeReactGA(this.props.location.pathname);

    getContents(this.props.match.params.id).then(res => {
      let contentListData = res;
      let slice = contentListData.slice(this.state.offset, this.state.offset + this.state.parPage);
      let count = contentListData.length;
      this.setState({
        pageCount: Math.ceil(contentListData.length / this.state.parPage),
        orgtabledata: contentListData,
        contentList: slice,
        loading: false,
        count:count
      });
    });
    // eslint-disable-next-line no-undefined
    if (this.props.match.params.id === undefined) {
      this.props.history.push("/401");
    }
  };

  filterUpdate(e) {
    let updatedList = this.state.orgtabledata;
    const searchText = e.target.value;
    if(searchText){
      updatedList = updatedList.filter((item) => {
        return item.title.toLowerCase().search(e.target.value.toLowerCase()) !== -1;
      });
      this.setState({
        contentList: updatedList.map((content, index) =>
          content.id  ? { ...content, count: index+1 } : content,
        ), 
        searchText: searchText
      });
    }
    else if(searchText === "" || searchText === null){
      let slice = updatedList.slice(this.state.offset, this.state.offset + this.state.parPage);
      this.setState({
        filterData: [],
        contentList: slice
      });
    }
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const searchBox = document.getElementById("searchBox");
    if(searchBox.value){
      searchBox.value = "";
    }
    const offset = selectedPage * this.state.parPage;
    this.setState({
      currentPage :selectedPage,
      offset:offset,
    },()=>{
      this.loadMoreData();
    });
  }
  loadMoreData() {
    let showMoreData = this.state.orgtabledata;
    const slice= showMoreData.slice(this.state.offset, this.state.offset + this.state.parPage);
    this.setState({ 
      pageCount: Math.ceil(showMoreData.length / this.state.parPage),
      contentList: slice
    });
  }
  // Toggle status - Active / Inactive
  handleStatus(id, user_id, active, title, library_id, library_type) {
    toggleActive(id, user_id, library_id, !active);
    let contentListData = this.state.orgtabledata;
    contentListData.map(content => {
      if(content.id === id){
        content.active = !active;
      }
      return null;
    });
    let slice = contentListData.slice(this.state.offset, this.state.offset + this.state.parPage);
    let count = contentListData.length;
    this.setState(({
      orgtabledata: this.state.orgtabledata.map(content =>
        content.id === id ? { ...content, active: !active } : content,
      ),
      pageCount: Math.ceil(contentListData.length / this.state.parPage),
      contentList: slice,
      loading: false,
      count:count
    }));
    swal.fire({
      allowOutsideClick: false,
      icon: "success",
      title: `${title} - ${library_type} has been ${
        !active ? "Enabled" : "Disabled"
      }`,
      text: `This component will be ${
        !active ? "available" : "unavailable"
      } in the Loree-editor`,
      showConfirmButton: true,
    });
  }
  handleDelete(id, index, user_id, title, library_type) {
    swal
      .fire({
        allowOutsideClick: false,
        title: "Are you sure for DELETE?",
        text: `Deleting ${title} - ${library_type} permanently, won't be able to revert this. Also this component will be removed from all used places.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
      .then(result => {
        if (result.value) {
          swal.fire({
            allowOutsideClick: false,
            title: "Deleted",
            text: "Your content has been deleted.",
            icon: "success",
            showConfirmButton: true,
          });
          deleteContent(id, user_id);
          let showMoreData = this.state.orgtabledata;
          showMoreData = showMoreData.filter(function(el) { return el.id !== id; }); 
          showMoreData.map((data,index) => {
            data.count = index + 1;
            return null;
          });
          const searchBox = document.getElementById("searchBox");
          let slice = showMoreData.slice(this.state.offset, this.state.offset + this.state.parPage);  
          if(searchBox.value === ""){
            this.setState({
              pageCount: Math.ceil(showMoreData.length / this.state.parPage),
              orgtabledata: showMoreData,
              contentList:slice
            });
          }else{
            const remian = this.state.contentList;
            remian.splice(index,1);
            this.setState({
              orgtabledata: showMoreData,
              contentList:remian.map((content, index) =>
                content.id  ? { ...content, count: index+1 } : content,
              )
            });
          }
        }
      });
  }

  render() {
    // eslint-disable-next-line no-undefined
    const { contentList, filterData } = this.state;
    const listData = (filterData.length === 0) ? contentList : (contentList) ? filterData : null; 
    const list = listData
      .map((list, index) => {
        const accordionEditLink = `/content/${list.library_type}/${list.user_id}/${list.id}/${sessionStorage.getItem("org_id")}/${sessionStorage.getItem("loreeVersion")}`;
        const buttonEditLink = `/button/edit/${list.user_id}/${list.id}/${sessionStorage.getItem("org_id")}/${sessionStorage.getItem("loreeVersion")}`;
        const mcqEditLink = `/multiplechoice/edit/${list.user_id}/${list.id}/${sessionStorage.getItem("org_id")}/${sessionStorage.getItem("loreeVersion")}`;
        const flipCardEditLink = `/flipcard/edit/${list.user_id}/${list.id}/${sessionStorage.getItem("org_id")}/${sessionStorage.getItem("loreeVersion")}`;
        const accordionDuplicateLink = `/duplicate/${list.library_type}/${list.user_id}/${list.id}/${sessionStorage.getItem("org_id")}/${sessionStorage.getItem("loreeVersion")}`;
        const buttonDuplicateLink = `/button/duplicate/${list.user_id}/${list.id}/${sessionStorage.getItem("org_id")}/${sessionStorage.getItem("loreeVersion")}`;
        const mcqDuplicateLink = `/multiplechoice/duplicate/${list.user_id}/${list.id}/${sessionStorage.getItem("org_id")}/${sessionStorage.getItem("loreeVersion")}`;
        const flipCardDuplicateLink = `/flipcard/duplicate/${list.user_id}/${list.id}/${sessionStorage.getItem("org_id")}/${sessionStorage.getItem("loreeVersion")}`;
        return (
          <tr key={index}>
            <td className="text-center">{list.count}.</td>
            <td className = "pagination-table-style">
              <Link
                to={{
                  pathname: `/contentview/${list.library_type}/${list.user_id}/${list.id}`,
                  state: {
                    user: localStorage.getItem("user"),
                    user_id: sessionStorage.getItem("user_id"),
                  },
                }}
              >
                {list.title}
              </Link>
            </td>
            <td className="text-capitalize">{list.library_type}</td>
            <td className="text-capitalize">
              <Form.Check
                type="switch"
                id={`customSwitchesChecked${index}`}
                checked={list.active}
                label={list.active ? "Active" : "Inactive"}
                onChange={this.handleStatus.bind(
                  this,
                  list.id,
                  list.user_id,
                  list.active,
                  list.title,
                  list.library_id,
                  list.library_type,
                )}
              />
            </td>
            {(() => {
              if (list.library_type === "Button") {
                return (
                  <td className="text-center">
                    <Link
                      to={{
                        pathname: buttonEditLink,
                        state: {
                          user: localStorage.getItem("user"),
                          user_id: sessionStorage.getItem("user_id"),
                        },
                      }}
                    >
                      <i title = "Edit" className="fas fa-edit"></i>
                    </Link>
                  </td>
                );
              } else if (list.library_type === "multiplechoice") {
                return (
                  <td className="text-center">
                    <Link
                      to={{
                        pathname: mcqEditLink,
                        state: {
                          user: localStorage.getItem("user"),
                          user_id: sessionStorage.getItem("user_id"),
                        },
                      }}
                    >
                      <i title = "Edit" className="fas fa-edit"></i>
                    </Link>
                  </td>
                );
              }
              else if (list.library_type === "flipcard") {
                return <td className="text-center"><Link to={{
                  pathname: flipCardEditLink,
                  state: {
                    user: localStorage.getItem("user"),
                    user_id: sessionStorage.getItem("user_id")
                  }
                }}>
                  <i title = "Edit" className="fas fa-edit"></i>
                </Link></td>;
              }
              else {
                return (
                  <td className="text-center">
                    <Link
                      to={{
                        pathname: accordionEditLink,
                        state: {
                          user: localStorage.getItem("user"),
                          user_id: sessionStorage.getItem("user_id"),
                        },
                      }}
                    >
                      <i title = "Edit" className="fas fa-edit"></i>
                    </Link>
                  </td>
                );
              }
            })()}
            {(() => {
              if (list.library_type === "Button") {
                return (
                  <td className="text-center">
                    <Link
                      className= "text-secondary"
                      to={{
                        pathname: buttonDuplicateLink,
                        state: {
                          contentList: this.state.orgtabledata
                        },
                      }}
                    >
                      <i title="Duplicate" className="far fa-copy"></i>
                    </Link>
                  </td>
                );
              } else if (list.library_type === "multiplechoice") {
                return (
                  <td className="text-center">
                    <Link
                      className= "text-secondary"
                      to={{
                        pathname: mcqDuplicateLink,
                        state: {
                          contentList: this.state.orgtabledata
                        },
                      }}
                    >
                      <i title="Duplicate" className="far fa-copy"></i>
                    </Link>
                  </td>
                );
              }
              else if (list.library_type === "flipcard") {
                return <td className="text-center"><Link className= "text-secondary" to={{
                  pathname: flipCardDuplicateLink,
                  state: {
                    contentList: this.state.orgtabledata
                  }
                }}><i title="Duplicate" className="far fa-copy"></i></Link></td>;
              }
              else {
                return (
                  <td className="text-center">
                    <Link
                      className= "text-secondary"
                      to={{
                        pathname: accordionDuplicateLink,
                        state: {
                          contentList: this.state.orgtabledata
                        },
                      }}
                    >
                      <i title="Duplicate" className="far fa-copy"></i>
                    </Link>
                  </td>
                );
              }
            })()}
            <td className="text-center">
              <Link
                to="#"
                className="text-danger"
                onClick={this.handleDelete.bind(
                  this,
                  list.id,
                  list.count,
                  list.user_id,
                  list.title,
                  list.library_type,
                )}
              >
                <i title= "Delete" className="far fa-trash-alt"></i>
              </Link>
            </td>
          </tr>
        );
      });
    if (this.state.loading) return <CircleLoader width={100} height={100} color={"black"} />;
    else if(sessionStorage.getItem("user_id") !== null) {
      return (
        <React.Fragment>
          <Header
            user={localStorage.getItem("user")}
            user_id={sessionStorage.getItem("user_id")}
            data={this.props}
          />
          <Container className="margin">
            <Card>
              <Card.Header as="h5" className="header">
                Content List
                <Form inline className="float-right" onSubmit={e => { e.preventDefault(); }}>
                  <FormControl
                    id = "searchBox"
                    type="text"
                    placeholder="Search the titles.."
                    className="mr-sm-2 float-right"
                    onChange={this.filterUpdate.bind(this)}
                    size="sm"
                    autoComplete="off"
                  />
                </Form>
              </Card.Header>
              <Card.Body className="p-0">
                <Table striped responsive hover className="mb-0">
                  <thead>
                    <tr>
                      <th className="text-center w-8">S.No</th>
                      <th className="text-center w-50">Title</th>
                      <th className="w-15">Library Type</th>
                      <th className="w-10">Status</th>
                      <th className="text-center w-17" colSpan="3">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  {(() => {
                    if (list.length === 0) {
                      return (
                        <tbody>
                          <tr>
                            <td colSpan="5" style={{ textAlign: "center" }}>No results found</td>
                          </tr>
                        </tbody>
                      );
                    }
                    else {
                      return (
                        <tbody>{list}</tbody>
                      );
                    }
                  })()}
                </Table>
                <div className ="react-paginate">
                  <ReactPaginate
                    previousLabel={"← Previous"}
                    nextLabel={"Next →"}
                    breakLabel={<span className="gap">...</span>}
                    pageCount={this.state.pageCount}
                    breakClassName = {"break-me"}
                    marginPagesDisplayed ={2}
                    pageRangeDisplayed = {5}
                    onPageChange={this.handlePageClick}
                    forcePage={this.state.currentPage}
                    containerClassName={"pagination"}
                    subContainerClassName = {"pages pagination"}
                    previousLinkClassName={"previous_page"}
                    nextLinkClassName={"next_page"}
                    disabledClassName={"disabled"}
                    activeClassName={"active"}/>
                </div>
              </Card.Body>
            </Card>
          </Container>
        </React.Fragment>
      );
    }
    else{
      return <Redirect to="/401" />;
    }
  }
}
export default ContentList;
